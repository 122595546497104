<template>
  <div>
    <div class="container">
      <div class="flex justify-end pt-2 font-medium text-white">
        <a href="/" class="px-2">English</a> |
        <a href="/bm" class="px-2">Bahasa Malaysia</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>