<template>
  <div class="bg-bottom bg-no-repeat bg-cover" style="background-image: url('/images/bg-banner.jpg')">
    <div class="container relative py-24 lg:py-32 xl:py-40">
      <div class="p-5 text-white bg-black bg-opacity-50 md:ml-auto md:w-3/4 lg:w-2/3">
        <p class="mb-2 text-2xl font-medium">Want to Find</p>
        <h1 class="text-3xl font-bold leading-none uppercase text-brown-1">
          MOSQUE CARPET SUPPLIERS AND INSTALLERS AS LOW AS RM2.89/SQFT?
        </h1>
        <ul class="py-3 font-light xl:text-lg md:flex md:flex-wrap">
          <li class="inline-flex md:w-1/2">
            <i class="pt-1 pr-2 fa-solid fa-check"></i>
            <p>We are the supplier and distributor of carpets in the Masjidil Haram</p>
          </li>
          <li class="md:w-1/2">
            <i class="pr-2 fa-solid fa-check"></i>100% Bumiputera ownership
          </li>
          <li class="md:w-1/2">
            <i class="pr-2 fa-solid fa-check"></i>100% Halal & Pure Threads
          </li>
          <!-- <li class="md:w-1/2">
              <i class="pr-2 fa-solid fa-check"></i>Konsultasi Percuma
            </li>
            <li class="md:w-1/2">
              <i class="pr-2 fa-solid fa-check"></i>Pemasangan Percuma
            </li>
            <li class="md:w-1/2">
              <i class="pr-2 fa-solid fa-check"></i>Pasang Dahulu dan Bayar
              Kemudian!
            </li> -->
        </ul>
        <div v-if="!hideEnquiry" class="mt-5 space-y-2 xl:text-lg md:flex md:space-y-0 md:space-x-1">
          <p class="py-1 text-center transition duration-200 transform rounded bg-gold-1 xl:w-48 hover:scale-110 w-44">
            <i class="pr-2 fa-solid fa-phone"></i>
            <a href="tel:+60122543135">Get A Free Quote</a>
          </p>
          <p class="py-1 text-center transition duration-200 transform rounded bg-gold-1 xl:w-48 hover:scale-110 w-44">
            <i class="pr-2 fa-brands fa-whatsapp"></i>
            <a href="https://wa.me/60122543135">012-2543 135</a>
          </p>
        </div>
      </div>
      <div class="absolute z-10 w-40 lg:w-64 right-3 bottom-2">
        <img src="/images/ramadan.png" alt="" class="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
