<template>
  <div class="home">
    <WaBtn />
    <header class="fixed z-20 w-full py-2 bg-green-5">
      <div class="container">
        <LanguageSelect />
        <div class="flex items-center justify-between">
          <img src="/images/main-logo.png" alt="" class="w-48 md:w-56 lg:w-80" />
          <div>
            <a href="tel:+60122543135" class="block transition duration-200 transform lg:hidden hover:scale-110">
              <img src="/images/phone.png" alt="" class="w-12" />
            </a>

            <p
              class="hidden p-3 text-2xl font-medium leading-tight text-right text-white border-2 border-white rounded-md lg:block">
              <a href="tel:+60122543135"> Get A <span>Free Quote</span></a>
            </p>
          </div>
        </div>
      </div>
    </header>
    <div class="h-20 bg-gray-500"></div>

    <MainBanner />

    <!-- about us -->
    <div class="py-20">
      <div class="container">
        <h2 class="text-3xl font-semibold text-center text-gray-900 uppercase">
          What <span class="text-green-5">We Do</span>
        </h2>
        <div class="mt-4 text-base leading-tight text-center text-gray-600 lg:max-w-3xl lg:mx-auto">
          <p>
            <strong>HK Carpets</strong> is a well-known name in the field of
            Masjid & Surau Carpet and we were established since 1998 with more
            20 years’ experience in this industry
          </p>
        </div>
      </div>
    </div>

    <!-- provide -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-provide.jpg')">
      <div class="container">
        <h2 class="text-3xl font-semibold text-center text-white uppercase">
          We <span class="text-green-5">Provide</span>
        </h2>
        <div class="flex flex-wrap mt-8 lg:max-w-3xl lg:mx-auto">
          <div v-for="(item, i) in provide" :key="i" class="w-1/2 p-1 md:w-1/4">
            <div class="p-4 transition duration-200 ease-linear border-2 bg-opacity-60 hover:bg-gold-1 border-gold-1">
              <img :src="item.image + '.png'" :alt="item.alt" class="w-16 mx-auto" />
              <p class="pt-3 text-xl text-center text-white">{{ item.alt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- promotion banner -->
    <div class="py-10 bg-green-6">
      <div class="container">
        <div class="md:flex md:items-center lg:justify-center">
          <div class="md:w-1/2 lg:w-1/3 md:order-2">
            <p class="pb-5 text-2xl font-bold text-center text-white uppercase lg:text-3xl lg:text-left">
              Limited Time <span class="text-brown-1">Offer</span>
            </p>
            <div class="relative p-4 text-white border-4 border-white">
              <p class="text-3xl font-bold uppercase xl:text-4xl">
                CARPET RAUDHAH <br /><span class="text-yellow-400">RM 5.99</span>
              </p>
              <!-- <p class="text-2xl font-semibold xl:text-3xl">RM 7.89</p> -->
              <!-- <p class="text-lg xl:text-xl">Siap Pasang <br> (Min 1000 SQFT)</p> -->
              <ul class="pt-4 pl-4 space-y-3 text-lg leading-tight list-disc list-outside">
                <li>100% Halal & Pure Threads </li>
                <li>Thickness Up To 13mm</li>
                <li>FREE Underlay Thickness 7mm</li>
                <li>As Low As RM 5.99/sqft (Ready To Install) <br>
                  (Minimum Order 1076.4 sqft)</li>
              </ul>
              <div class="pt-5">
                <p class="w-40 py-1 font-bold text-center text-white bg-red-600 rounded-md xl:w-52 xl:text-lg">
                  <a
                    href="https://wa.me/60122543135?text=Hi,%20I%20want%20to%20know%20more%20about%20the%20RM7.89%20raudah%20carpet%20promotion">Get
                    This Deal! <br> Limited Stock!</a>
                </p>
              </div>
              <div class="absolute w-32 xl:w-36 -right-3 -bottom-14">
                <img src="/images/promo.png" alt="" class="" />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mt-6 md:flex-wrap md:w-1/2 md:mt-0 md:pr-3">
            <img src="/images/promo-2.jpg" alt="" class="w-1/3 pb-2 md:pb-0 lg:w-1/3" />
            <img src="/images/promo-1.jpg" alt="" class="w-1/3 pb-2 md:pb-0 lg:w-1/3" />
            <img src="/images/promo-3.jpg" alt="" class="w-1/3 pb-2 md:pb-0 lg:w-1/3" />
            <img src="/images/promo-4.jpg" alt="" class="w-1/3 lg:w-1/3" />
            <img src="/images/promo-5.jpg" alt="" class="w-1/3 lg:w-1/3" />
            <img src="/images/promo-6.jpg" alt="" class="w-1/3 lg:w-1/3" />
          </div>
        </div>
      </div>
    </div>
    <!-- speciality -->
    <div class="py-20">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="mb-6 lg:w-1/2 lg:mb-0 lg:pl-5 lg:order-2">
            <h2 class="text-4xl font-bold text-gray-900 uppercase">
              Our <span class="text-green-5">Specialist Services</span>
            </h2>
            <ul class="mt-4 space-y-2 leading-tight text-gray-600">
              <li v-for="list in speciality" :key="list" class="flex items-center">
                <i class="pr-2 text-2xl fa-regular text-green-5 fa-square-check"></i>
                <p class="leading-tight">
                  {{ list }}
                </p>
              </li>
            </ul>
            <div class="flex mt-5 space-x-1 text-white">
              <p class="px-1 py-1 text-center transition duration-200 transform rounded bg-green-5 hover:scale-110">
                <i class="pr-2 fa-solid fa-phone"></i>
                <a href="tel:+60122543135">Get A Free Quote</a>
              </p>
              <p class="py-1 text-center transition duration-200 transform rounded bg-green-5 hover:scale-110 w-44">
                <i class="pr-2 fa-brands fa-whatsapp"></i>
                <a href="https://wa.me/60122543135">012-2543 135</a>
              </p>
            </div>
          </div>
          <div class="lg:w-1/2 lg:order-1">
            <img src="/images/speciality-1.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </div>
    <!-- product  -->
    <div class="py-10 bg-green-6">
      <div class="container">
        <h2 class="pb-3 text-3xl font-semibold text-center text-white uppercase">
          Our <span>Products</span>
        </h2>
        <p class="text-xl text-center text-white">Masjid & Surau Carpets</p>
        <p class="pt-2 text-sm leading-tight text-center text-gray-100">
          Masjid & Surau Carpets is available in different designs and colors.
          It is made with a traditionally Heatset woven construction allowing a
          varied choice of colors, designs and fibers to create a hard wearing
          and luxurious mosque carpet for hospitality.The mosque carpets with
          100% Solution Dyed Synthetic to produce a Dense Weave carpet that
          wears well and retains its appearance for years.
        </p>
        <div class="flex flex-wrap mt-8 lg:justify-center">
          <div v-for="(item, i) in product" :key="i" class="px-1 pb-3 md:w-1/3 lg:w-1/3">
            <div class="relative overflow-hidden product">
              <img :src="'/images/product/' + item.image + '.jpg'" :alt="item.alt" class="w-full h-auto" />
              <!-- <div
                class="absolute top-0 left-0 w-full h-full transition duration-300 ease-linear bg-opacity-50 opacity-0 bg-gold-1 hover:opacity-100"
              ></div> -->
            </div>
            <p class="mt-2 text-base font-semibold text-center text-white">
              {{ item.alt }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <h2 class="pb-3 text-3xl font-semibold text-center text-gray-900 uppercase">
          Why <span class="text-green-5">Choose Us</span>
        </h2>

        <div class="mt-8 md:flex md:flex-wrap lg:justify-center">
          <div v-for="(item, i) in choose_us" :key="i" class="pb-1 md:px-1 md:w-1/2 lg:w-1/3">
            <div :class="item.bg" class="flex items-center justify-center px-3 py-8 text-white">
              <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-1/5" />
              <p class="w-4/5 pl-3 text-xl font-light leading-tight uppercase">
                {{ item.alt }}
              </p>
            </div>
            <p :class="item.sub_bg" class="p-3 leading-tight text-center text-white md:h-16">
              {{ item.p }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- project -->
    <div class="py-10 bg-green-6">
      <div class="container">
        <h2 class="pb-3 text-3xl font-semibold text-center text-white uppercase">
          Our <span>Projects</span>
        </h2>

        <carousel :autoplay="true" :loop="true" :dots="true" :autoplayTimeout="6000" :responsive="{
            0: { items: 1, nav: false },
            768: { items: 3, nav: false },
            1024: { items: 4, nav: false },
          }">
          <div v-for="image in project" :key="image" class="mt-8">
            <img :src="'/images/project/' + image + '.jpg'" alt="" />
          </div>
        </carousel>
      </div>
    </div>
    <!-- testimonial -->
    <div class="py-10">
      <div class="container">
        <h2 class="pb-3 text-3xl font-semibold text-center text-gray-900 uppercase">
          What Our <span class="text-green-5">Customer Said</span>
        </h2>

        <div class="mt-8 lg:max-w-5xl lg:mx-auto">
          <carousel :autoplay="true" :loop="true" :dots="false" :autoplayTimeout="6000" :responsive="{
            0: { items: 1, nav: false },
            768: { items: 3, nav: false },
            1024: { items: 4, nav: false },
          }">
            <div v-for="(item, i) in testimoni" :key="i" class="px-1">
              <div class="bg-green-6 h-0.5"></div>
              <h2 class="pt-5 text-2xl font-semibold text-green-5">
                {{ item.h2 }}
              </h2>
              <p v-if="item.company" class="text-gray-800">
                {{ item.company }}
              </p>
              <div class="pt-6 text-sm italic text-gray-600">
                <p>
                  {{ item.p }}
                </p>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <!-- about us -->
    <div id="element" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="pb-10 lg:w-1/2 lg:pb-0">
            <h2 class="pb-3 text-3xl font-semibold text-center text-white uppercase lg:text-4xl">
              About <span class="text-brown-1">Us</span>
            </h2>
            <div class="space-y-3 text-base leading-tight text-center text-white lg:text-left">
              <p>
                HK Carpets was established in the year 1998. We are importers of
                all types of office, commercial & exhibition carpets. Our
                services include:
              </p>
              <ul class="pl-4 space-y-1 text-left list-disc list-outside lg:pl-14 md:pl-52">
                <li>Wall to wall carpets for office & commercial buildings</li>
                <li>Carpet tiles</li>
                <li>Mosque carpets</li>
                <li>Vinyl / laminated flooring</li>
                <li>All kinds of blinds</li>
              </ul>
              <p>
                Our promise is to give you the BEST AFFORDABLE PRICE and
                SERVICE. Look no further, you are at the right place.
              </p>
            </div>
          </div>
          <div class="lg:w-1/2">
            <!-- enquiry form  -->
            <div class="p-6 bg-green-6 bg-opacity-60 rounded-xl">
              <h1 class="text-2xl font-bold leading-tight text-center text-white capitalize lg:text-4xl">
                Send Us An Enquiry
              </h1>
              <div class="pt-8">
                <!-- feedback.activamedia.com.sg script begins here -->
                <iframe allowTransparency="true" style="min-height:450px; height:inherit; overflow:auto;" width="100%"
                  id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
                  src="https://feedback.activamedia.com.sg/my-contact-form-6642346.html?customVars123=yes&hasEmbedFormStyle=1">
                  <p>Your browser does not support iframes. The contact form cannot be displayed. Please use another
                    contact method (phone, fax etc)</p>
                </iframe>
                <!-- feedback.activamedia.com.sg script ends here -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="bg-green-6">
      <div class="container">
        <div class="py-10">
          <img src="/images/main-logo.png" alt="" class="w-64 mx-auto lg:w-auto" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="text-xl font-extrabold text-brown-1">Headquarter</h1>
              <h1 class="text-base font-medium leading-tight md:text-xl">
                No 1, Lintang Tamarind, Southern Park, 41200 Klang, Selangor.
              </h1>
              <h1 class="pt-5 text-xl font-extrabold text-brown-1">
                Warehouse
              </h1>
              <h1 class="text-base font-medium leading-tight md:text-xl">
                No 32, Lorong Jelutong, Southern Park, 41200, Klang, Selangor.
              </h1>
              <div class="pt-4 font-medium lg:text-lg">
                <p>
                  Call Us At :
                  <a href="tel:+60122543135"
                    class="transition duration-200 ease-in-out transform hover:text-brown-1">012-254 3135</a>
                </p>
                <p>
                  Email :
                  <a href="mailto:arabya5152@gmail.com"
                    class="transition duration-200 ease-in-out transform hover:text-brown-1">arabya5152@gmail.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- google map  -->
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7968.478740936029!2d101.439576!3d3.030316!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x81b2a449a28baf28!2sHK%20Carpets!5e0!3m2!1sen!2smy!4v1670811281503!5m2!1sen!2smy"
        width="100%" height="300" style="border: 0" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="py-3 bg-gold-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue"
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";

export default {
  name: 'HomeView',
  components: {
    WaBtn,
    MainBanner,
    carousel,
    EnquiryForm,
    LanguageSelect
  },
  data() {
    return {
      provide: [
        {
          image: "/images/provide-1",
          alt: "Import",
        },
        {
          image: "/images/provide-2",
          alt: "Retail",
        },
        {
          image: "/images/provide-3",
          alt: "Wholesale",
        },
        {
          image: "/images/provide-4",
          alt: "Export",
        },
      ],
      speciality: [
        'Installation within 3 days',
        'Free Quote and Consultation',
        'Patterns and Designs are inspired by Mosques in Mecca and Medina',
        'Neat and Careful Installation with Fully Equipped',
        'Installation is done by staff with over 40 years of experience',
      ],
      product: [
        { image: "product-1", alt: "Al Akbar 15mm" },
        { image: "product-2", alt: "Al Alif 15mm" },
        { image: "product-3", alt: "Al Salam 10mm" },
        { image: "product-4", alt: "Madinah 1.5 million 18mm" },
        { image: "product-5", alt: "Mecca 16mm" },
        { image: "product-6", alt: "Nabawi 12mm" },
        { image: "product-7", alt: "Raudhah 16mm" },
        { image: "product-8", alt: "Raudhah Platinum Diamond 18mm" },
        { image: "product-9", alt: "Syawal 15mm" },
        { image: "product-10", alt: "Al Afiya" },
        { image: "product-11", alt: "Al Arabia Raudhah" },
        { image: "product-12", alt: "Raudhah" },
      ],
      choose_us: [
        {
          bg: "bg-green-5",
          image: "choose-1",
          alt: "Free Site Visit and Quotation",
          p: "We Provide Free Site Visit and Quotation",
          sub_bg: "bg-green-2",
        },
        {
          bg: "bg-green-5",
          image: "choose-2",
          alt: "Installation within a Week",
          p: "Upon confirmation installation will be done within a week",
          sub_bg: "bg-green-2",
        },
        {
          bg: "bg-green-5",
          image: "choose-3",
          alt: "Affordable Price",
          p: "We Have Variety Of Carpets FOR ALL KINDS OF BUDGET",
          sub_bg: "bg-green-2",
        },
        {
          bg: "bg-green-5",
          image: "choose-4",
          alt: "Quality Assurance",
          p: "We Strive Hard to Provide The Best Quality",
          sub_bg: "bg-green-2",
        },
        {
          bg: "bg-green-5",
          image: "choose-5",
          alt: "Customer Satisfaction",
          p: "More than 98% Customer Satisfaction",
          sub_bg: "bg-green-2",
        },
      ],
      project: [
        "slide-2",
        "slide-3",
        "slide-6",
        "slide-10",
        "slide-11",
        "slide-12",
        "slide-13",
        "slide-14",
        "slide-16",
        "slide-17",
        "slide-19",
        "slide-20",
        "slide-21",
        "slide-22",
      ],
      testimoni: [
        {
          h2: "Masjid Bandar Ampang",
          p: "karpet madinah cantik dan berkualiti tinggi",
        },
        {
          h2: "Masjid Angkasapuri",
          p: "Kualiti karpet raudhah memang memuaskan.",
        },
        {
          h2: "Masjid Gariah Genting",
          p: "Karpet yg dipasang cantik dan kemas",
        },
        {
          h2: "Masjid Bdr Tun Razak",
          p: "Kemas dan cantik. Selesa untuk solat.",
        },
        {
          h2: "Masjid Muttaqin",
          p: "Hk karpet adalah no 1 dalam karpet Masjid..",
        },
        {
          h2: "Masjid Al Aman",
          p: "Memang puas hati dengan mutu dan kualiti pemasangan",
        },
        {
          h2: "Masjid Yayasan Noah",
          p: "Kualiti karpet dan pemasangan sangat tinggi",
        },
      ],
    }
  }
}
</script>
